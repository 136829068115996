import "../../assets/scss/Footer/Footer.scss";
import { LinkType } from "../../interfaces/LinkType";
import logo from "../../resources/logo/fresenius-kabi.png";
import { generateFooterLinks } from "../../util/util";

export interface DynamicFooterType {
  disclaimer_normal: string;
  disclaimer_situational: string;
  disclaimer_situational_vis: boolean;
  disclaimer_adverse: string;
  disclaimer_adverse_vis: boolean;  
  date: string;
  links: Array<LinkType>;
  legal_number: string;
  preparation_date: string;
  show_situational: boolean;
  show_adverse: boolean;  
  isAuthenticated?: boolean;
  userRoles?: string[];
  envSettings?: {
    alwaysShowSituational?: boolean;
    showIdacioSituational?: boolean;
    showTyenneSituational?: boolean;
    showHcpSituational?: boolean;
    showNonAuthSituational?: boolean;
    alwaysShowAdverse?: boolean;
    showIdacioAdverse?: boolean;
    showTyenneAdverse?: boolean;
    showHcpAdverse?: boolean;
    showNonAuthAdverse?: boolean;    
  };  
}

export default function DynamicFooter({
  disclaimer_normal = "",
  disclaimer_situational = "",
  disclaimer_situational_vis = false,
  disclaimer_adverse = "",
  disclaimer_adverse_vis = false,  
  date = "",
  links = [],
  legal_number = "",
  preparation_date = "",
  show_situational = false,
  show_adverse = false,  
  isAuthenticated = false,
  userRoles = [],
  envSettings = {
    alwaysShowSituational: false,
    showIdacioSituational: false,
    showTyenneSituational: false,
    showHcpSituational: false,
    showNonAuthSituational: false,
    alwaysShowAdverse: false,
    showIdacioAdverse: false,
    showTyenneAdverse: false,
    showHcpAdverse: false,
    showNonAuthAdverse: false,      
  },
}: DynamicFooterType) {
  const showSituationalDisclaimer = 
    show_situational === true ||
    disclaimer_situational_vis === true ||
    envSettings?.alwaysShowSituational ||
    (envSettings?.showIdacioSituational && userRoles?.includes("idacio")) ||
    (envSettings?.showTyenneSituational && userRoles?.includes("tyenne")) ||
    (envSettings?.showHcpSituational && userRoles?.includes("hcp")) ||
    (envSettings?.showNonAuthSituational && !isAuthenticated);  

  const showAdverseDisclaimer = 
    show_adverse === true ||
    disclaimer_adverse_vis === true ||
    envSettings?.alwaysShowAdverse ||
    (envSettings?.showIdacioAdverse && userRoles?.includes("idacio")) ||
    (envSettings?.showTyenneAdverse && userRoles?.includes("tyenne")) ||
    (envSettings?.showHcpAdverse && userRoles?.includes("hcp")) ||
    (envSettings?.showNonAuthAdverse && !isAuthenticated);    
  
  return (
    <div className={"footer"}>
      <div className="footer-inner-container">
        {showSituationalDisclaimer && (
          <div
            className={"disclaimer situational"}
            dangerouslySetInnerHTML={{
              __html: disclaimer_situational,
            }}
          />
        )}
        {showAdverseDisclaimer && (
          <div
            className={"disclaimer situational"}
            dangerouslySetInnerHTML={{
              __html: disclaimer_adverse,
            }}
          />
        )}        
        <div
          className={"disclaimer"}
          dangerouslySetInnerHTML={{
            __html: disclaimer_normal,
          }}
        ></div>
        <div className={"bottom"}>
          <div className={"logo"}>
            <img src={logo} alt="Logo" width="238" height="64" />
          </div>
          <div className={"content"}>
            <div className="preparation-container">
              <div className={"date"}>
                <p>{preparation_date}</p>
              </div>
              <div className="legal-number">{legal_number}</div>
            </div>
            <div className={"links"}>{generateFooterLinks(links)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
